.clear-export-buttons {
    display: inline-block;
    margin-top: 10px;
}


@media (max-width: 960px) {
    .input-container input {
        display: flex;
    }
}

@media (max-width: 430px) {
    .clear-export-buttons {
        display: flex;
    }

}