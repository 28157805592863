/**
* Template Name: Bootslander
* Template URL: https://bootstrapmade.com/bootslander-free-bootstrap-landing-page-template/
* Updated: Aug 07 2024 with Bootstrap v5.3.3
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# Font & Color Variables
# Help: https://bootstrapmade.com/color-system/
--------------------------------------------------------------*/
/* Fonts */
:root {
    --default-font: "Roboto", system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --heading-font: "Raleway", sans-serif;
    --nav-font: "Poppins", sans-serif;
}

/* Global Colors - The following color variables are used throughout the website. Updating them here will change the color scheme of the entire website */
:root {
    --background-color: #ffffff;
    /* Background color for the entire website, including individual sections */
    --default-color: #444444;
    /* Default color used for the majority of the text content across the entire website */
    --heading-color: #040677;
    /* Color for headings, subheadings and title throughout the website */
    --accent-color: #1acc8d;
    /* Accent color that represents your brand on the website. It's used for buttons, links, and other elements that need to stand out */
    --surface-color: #ffffff;
    /* The surface color is used as a background of boxed elements within sections, such as cards, icon boxes, or other elements that require a visual separation from the global background. */
    --contrast-color: #ffffff;
    /* Contrast color for text, ensuring readability against backgrounds of accent, heading, or default colors. */
}

/* Nav Menu Colors - The following color variables are used specifically for the navigation menu. They are separate from the global colors to allow for more customization options */
:root {
    --nav-color: #ffffff;
    /* The default color of the main navmenu links */
    --nav-hover-color: #1acc8d;
    /* Applied to main navmenu links when they are hovered over or active */
    --nav-mobile-background-color: #ffffff;
    /* Used as the background color for mobile navigation menu */
    --nav-dropdown-background-color: #ffffff;
    /* Used as the background color for dropdown items that appear when hovering over primary navigation items */
    --nav-dropdown-color: #444444;
    /* Used for navigation links of the dropdown items in the navigation menu. */
    --nav-dropdown-hover-color: #1acc8d;
    /* Similar to --nav-hover-color, this color is applied to dropdown navigation links when they are hovered over. */
}

/* Color Presets - These classes override global colors when applied to any section or element, providing reuse of the sam color scheme. */

.light-background {
    --background-color: #f4f5fe;
    --surface-color: #ffffff;
}

.dark-background {
    --background-color: #08005e;
    --default-color: #ffffff;
    --heading-color: #ffffff;
    --surface-color: #0c0091;
    --contrast-color: #ffffff;
}

/* Smooth scroll */
:root {
    scroll-behavior: smooth;
}

/*--------------------------------------------------------------
  # General Styling & Shared Classes
  --------------------------------------------------------------*/
body {
    color: var(--default-color);
    background-color: var(--background-color);
    font-family: var(--default-font);
}

a {
    color: var(--accent-color);
    text-decoration: none;
    transition: 0.3s;
}

a:hover {
    color: color-mix(in srgb, var(--accent-color), transparent 25%);
    text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--heading-color);
    font-family: var(--heading-font);
}

/* PHP Email Form Messages
  ------------------------------*/
.php-email-form .error-message {
    display: none;
    background: #df1529;
    color: #ffffff;
    text-align: left;
    padding: 15px;
    margin-bottom: 24px;
    font-weight: 600;
}

.php-email-form .sent-message {
    display: none;
    color: #ffffff;
    background: #059652;
    text-align: center;
    padding: 15px;
    margin-bottom: 24px;
    font-weight: 600;
}

.php-email-form .loading {
    display: none;
    background: var(--surface-color);
    text-align: center;
    padding: 15px;
    margin-bottom: 24px;
}

.php-email-form .loading:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin: 0 10px -6px 0;
    border: 3px solid var(--accent-color);
    border-top-color: var(--surface-color);
    animation: php-email-form-loading 1s linear infinite;
}

@keyframes php-email-form-loading {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/*--------------------------------------------------------------
  # Global Header
  --------------------------------------------------------------*/
.header {
    --background-color: rgba(255, 255, 255, 0);
    --default-color: #ffffff;
    --heading-color: #ffffff;
    color: var(--default-color);
    background-color: var(--background-color);
    padding: 20px 0;
    transition: all 0.5s;
    z-index: 997;
}

.header .logo {
    line-height: 1;
}

.header .logo img {
    max-height: 32px;
    margin-right: 8px;
}

.header .logo h1 {
    font-size: 30px;
    margin: 0;
    font-weight: 700;
    color: var(--heading-color);
}

.scrolled .header {
    box-shadow: 0px 0 18px rgba(0, 0, 0, 0.1);
}

/* Global Header on Scroll
  ------------------------------*/
.scrolled .header {
    --background-color: #10058c;
}

/*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
/* Desktop Navigation */
@media (min-width: 1200px) {
    .navmenu {
        padding: 0;
    }

    .navmenu ul {
        margin: 0;
        padding: 0;
        display: flex;
        list-style: none;
        align-items: center;
    }

    .navmenu li {
        position: relative;
    }

    .navmenu>ul>li {
        white-space: nowrap;
        padding: 15px 14px;
    }

    .navmenu>ul>li:last-child {
        padding-right: 0;
    }

    .navmenu a,
    .navmenu a:focus {
        color: color-mix(in srgb, var(--nav-color), transparent 20%);
        font-size: 15px;
        padding: 0 2px;
        font-family: var(--nav-font);
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: space-between;
        white-space: nowrap;
        transition: 0.3s;
        position: relative;
    }

    .navmenu a i,
    .navmenu a:focus i {
        font-size: 12px;
        line-height: 0;
        margin-left: 5px;
        transition: 0.3s;
    }

    .navmenu>ul>li>a:before {
        content: "";
        position: absolute;
        height: 2px;
        bottom: -6px;
        left: 0;
        background-color: var(--nav-hover-color);
        visibility: hidden;
        width: 0px;
        transition: all 0.3s ease-in-out 0s;
    }

    .navmenu a:hover:before,
    .navmenu li:hover>a:before,
    .navmenu .active:before {
        visibility: visible;
        width: 25px;
    }

    .navmenu li:hover>a,
    .navmenu .active,
    .navmenu .active:focus {
        color: var(--nav-color);
    }

    .navmenu .dropdown ul {
        margin: 0;
        padding: 10px 0;
        background: var(--nav-dropdown-background-color);
        display: block;
        position: absolute;
        visibility: hidden;
        left: 14px;
        top: 130%;
        opacity: 0;
        transition: 0.3s;
        border-radius: 4px;
        z-index: 99;
        box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
    }

    .navmenu .dropdown ul li {
        min-width: 200px;
    }

    .navmenu .dropdown ul a {
        padding: 10px 20px;
        font-size: 15px;
        text-transform: none;
        color: var(--nav-dropdown-color);
    }

    .navmenu .dropdown ul a i {
        font-size: 12px;
    }

    .navmenu .dropdown ul a:hover,
    .navmenu .dropdown ul .active:hover,
    .navmenu .dropdown ul li:hover>a {
        color: var(--nav-dropdown-hover-color);
    }

    .navmenu .dropdown:hover>ul {
        opacity: 1;
        top: 100%;
        visibility: visible;
    }

    .navmenu .dropdown .dropdown ul {
        top: 0;
        left: -90%;
        visibility: hidden;
    }

    .navmenu .dropdown .dropdown:hover>ul {
        opacity: 1;
        top: 0;
        left: -100%;
        visibility: visible;
    }
}

/* Mobile Navigation */
@media (max-width: 1199px) {
    .mobile-nav-toggle {
        color: var(--nav-color);
        font-size: 28px;
        line-height: 0;
        margin-right: 10px;
        cursor: pointer;
        transition: color 0.3s;
    }

    .navmenu {
        padding: 0;
        z-index: 9997;
    }

    .navmenu ul {
        display: none;
        list-style: none;
        position: absolute;
        inset: 60px 20px 20px 20px;
        padding: 10px 0;
        margin: 0;
        border-radius: 6px;
        background-color: var(--nav-mobile-background-color);
        border: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
        box-shadow: none;
        overflow-y: auto;
        transition: 0.3s;
        z-index: 9998;
    }

    .navmenu a,
    .navmenu a:focus {
        color: var(--nav-dropdown-color);
        padding: 10px 20px;
        font-family: var(--nav-font);
        font-size: 17px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: space-between;
        white-space: nowrap;
        transition: 0.3s;
    }

    .navmenu a i,
    .navmenu a:focus i {
        font-size: 12px;
        line-height: 0;
        margin-left: 5px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        transition: 0.3s;
        background-color: color-mix(in srgb, var(--accent-color), transparent 90%);
    }

    .navmenu a i:hover,
    .navmenu a:focus i:hover {
        background-color: var(--accent-color);
        color: var(--contrast-color);
    }

    .navmenu a:hover,
    .navmenu .active,
    .navmenu .active:focus {
        color: var(--nav-dropdown-hover-color);
    }

    .navmenu .active i,
    .navmenu .active:focus i {
        background-color: var(--accent-color);
        color: var(--contrast-color);
        transform: rotate(180deg);
    }

    .navmenu .dropdown ul {
        position: static;
        display: none;
        z-index: 99;
        padding: 10px 0;
        margin: 10px 20px;
        background-color: var(--nav-dropdown-background-color);
        transition: all 0.5s ease-in-out;
    }

    .navmenu .dropdown ul ul {
        background-color: rgba(33, 37, 41, 0.1);
    }

    .navmenu .dropdown>.dropdown-active {
        display: block;
        background-color: rgba(33, 37, 41, 0.03);
    }

    .mobile-nav-active {
        overflow: hidden;
    }

    .mobile-nav-active .mobile-nav-toggle {
        color: #fff;
        position: absolute;
        font-size: 32px;
        top: 15px;
        right: 15px;
        margin-right: 0;
        z-index: 9999;
    }

    .mobile-nav-active .navmenu {
        position: fixed;
        overflow: hidden;
        inset: 0;
        background: rgba(33, 37, 41, 0.8);
        transition: 0.3s;
    }

    .mobile-nav-active .navmenu>ul {
        display: block;
    }
}

/*--------------------------------------------------------------
  # Global Footer
  --------------------------------------------------------------*/
.footer {
    color: var(--default-color);
    background-color: var(--background-color);
    font-size: 14px;
    position: relative;
}

.footer .footer-top {
    padding-top: 50px;
    border-top: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
}

.footer .footer-about .logo {
    line-height: 1;
    margin-bottom: 25px;
}

.footer .footer-about .logo img {
    max-height: 40px;
    margin-right: 6px;
}

.footer .footer-about .logo span {
    font-size: 26px;
    font-weight: 700;
    letter-spacing: 1px;
    font-family: var(--heading-font);
    color: var(--heading-color);
}

.footer .footer-about p {
    font-size: 14px;
    font-family: var(--heading-font);
}

.footer .social-links a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 1px solid color-mix(in srgb, var(--default-color), transparent 50%);
    font-size: 16px;
    color: color-mix(in srgb, var(--default-color), transparent 30%);
    margin-right: 10px;
    transition: 0.3s;
}

.footer .social-links a:hover {
    color: var(--accent-color);
    border-color: var(--accent-color);
}

.footer h4 {
    font-size: 16px;
    font-weight: bold;
    position: relative;
    padding-bottom: 12px;
}

.footer .footer-links {
    margin-bottom: 30px;
}

.footer .footer-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer .footer-links ul i {
    padding-right: 2px;
    font-size: 12px;
    line-height: 0;
}

.footer .footer-links ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
}

.footer .footer-links ul li:first-child {
    padding-top: 0;
}

.footer .footer-links ul a {
    color: color-mix(in srgb, var(--default-color), transparent 30%);
    display: inline-block;
    line-height: 1;
}

.footer .footer-links ul a:hover {
    color: var(--accent-color);
}

.footer .footer-contact p {
    margin-bottom: 5px;
}

.footer .footer-newsletter .newsletter-form {
    margin-top: 30px;
    margin-bottom: 15px;
    padding: 6px 8px;
    position: relative;
    border-radius: 4px;
    border: 1px solid color-mix(in srgb, var(--default-color), transparent 80%);
    display: flex;
    background-color: var(--background-color);
    transition: 0.3s;
}

.footer .footer-newsletter .newsletter-form:focus-within {
    border-color: var(--accent-color);
}

.footer .footer-newsletter .newsletter-form input[type=email] {
    border: 0;
    padding: 4px;
    width: 100%;
    background-color: var(--background-color);
    color: var(--default-color);
}

.footer .footer-newsletter .newsletter-form input[type=email]:focus-visible {
    outline: none;
}

.footer .footer-newsletter .newsletter-form input[type=submit] {
    border: 0;
    font-size: 16px;
    padding: 0 20px;
    margin: -7px -8px -7px 0;
    background: var(--accent-color);
    color: var(--contrast-color);
    transition: 0.3s;
    border-radius: 0 4px 4px 0;
}

.footer .footer-newsletter .newsletter-form input[type=submit]:hover {
    background: color-mix(in srgb, var(--accent-color), transparent 20%);
}

.footer .copyright {
    padding: 25px 0;
    border-top: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
}

.footer .copyright p {
    margin-bottom: 0;
}

.footer .credits {
    margin-top: 6px;
    font-size: 13px;
}

/*--------------------------------------------------------------
  # Preloader
  --------------------------------------------------------------*/
#preloader {
    position: fixed;
    inset: 0;
    z-index: 999999;
    overflow: hidden;
    background: var(--background-color);
    transition: all 0.6s ease-out;
}

#preloader:before {
    content: "";
    position: fixed;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    border: 6px solid #ffffff;
    border-color: var(--accent-color) transparent var(--accent-color) transparent;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    animation: animate-preloader 1.5s linear infinite;
}

@keyframes animate-preloader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

/*--------------------------------------------------------------
  # Scroll Top Button
  --------------------------------------------------------------*/
.scroll-top {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    right: 15px;
    bottom: 15px;
    z-index: 99999;
    background-color: var(--accent-color);
    width: 40px;
    height: 40px;
    border-radius: 4px;
    transition: all 0.4s;
}

.scroll-top i {
    font-size: 24px;
    color: var(--contrast-color);
    line-height: 0;
}

.scroll-top:hover {
    background-color: color-mix(in srgb, var(--accent-color), transparent 20%);
    color: var(--contrast-color);
}

.scroll-top.active {
    visibility: visible;
    opacity: 1;
}

/*--------------------------------------------------------------
  # Disable aos animation delay on mobile devices
  --------------------------------------------------------------*/
@media screen and (max-width: 768px) {
    [data-aos-delay] {
        transition-delay: 0 !important;
    }
}

/*--------------------------------------------------------------
  # Global Page Titles & Breadcrumbs
  --------------------------------------------------------------*/
.page-title {
    color: var(--default-color);
    background-color: var(--background-color);
    position: relative;
}

.page-title .heading {
    padding: 160px 0 80px 0;
    border-top: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
}

.page-title .heading h1 {
    font-size: 38px;
    font-weight: 700;
}

.page-title nav {
    background-color: color-mix(in srgb, var(--default-color), transparent 88%);
    padding: 20px 0;
}

.page-title nav ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0;
    font-size: 16px;
    font-weight: 600;
}

.page-title nav ol li+li {
    padding-left: 10px;
}

.page-title nav ol li+li::before {
    content: "/";
    display: inline-block;
    padding-right: 10px;
    color: color-mix(in srgb, var(--default-color), transparent 70%);
}

/*--------------------------------------------------------------
  # Global Sections
  --------------------------------------------------------------*/
section,
.section {
    color: var(--default-color);
    background-color: var(--background-color);
    padding: 60px 0;
    scroll-margin-top: 100px;
    overflow: clip;
}

@media (max-width: 1199px) {

    section,
    .section {
        scroll-margin-top: 66px;
    }
}

/*--------------------------------------------------------------
  # Global Section Titles
  --------------------------------------------------------------*/
.section-title {
    padding-bottom: 60px;
    position: relative;
}

.section-title h2 {
    font-size: 14px;
    font-weight: 500;
    padding: 0;
    line-height: 1px;
    margin: 0;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: color-mix(in srgb, var(--default-color), transparent 50%);
    position: relative;
}

.section-title h2::after {
    content: "";
    width: 120px;
    height: 1px;
    display: inline-block;
    background: var(--accent-color);
    margin: 4px 10px;
}

.section-title div {
    color: var(--heading-color);
    margin: 0;
    margin: 0;
    font-size: 28px;
    font-weight: 700;
    text-transform: uppercase;
    font-family: var(--heading-font);
}

/*--------------------------------------------------------------
  # Hero Section
  --------------------------------------------------------------*/
.hero {
    width: 100%;
    min-height: 70vh;
    position: relative;
    padding: 120px 0 120px 0;
    display: flex;
    align-items: center;
}

.hero .hero-bg {
    position: absolute;
    inset: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
}

.hero:before {
    content: "";
    background: color-mix(in srgb, var(--background-color), transparent 10%);
    position: absolute;
    inset: 0;
    z-index: 2;
}

.hero .container {
    position: relative;
    z-index: 3;
}

.hero h1 {
    margin: 0 0 20px 0;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    color: color-mix(in srgb, var(--heading-color), transparent 30%);
}

.hero h1 span {
    color: var(--heading-color);
    border-bottom: 4px solid var(--accent-color);
}

.hero p {
    color: color-mix(in srgb, var(--default-color), transparent 30%);
    margin: 5px 0 30px 0;
    font-size: 22px;
    font-weight: 400;
}

.hero .btn-get-started {
    color: var(--contrast-color);
    background: var(--accent-color);
    font-family: var(--heading-font);
    font-weight: 400;
    font-size: 15px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 28px 12px 28px;
    border-radius: 50px;
    transition: 0.5s;
    box-shadow: 0 8px 28px rgba(0, 0, 0, 0.1);
}

.hero .btn-get-started:hover {
    color: var(--contrast-color);
    background: color-mix(in srgb, var(--accent-color), transparent 15%);
    box-shadow: 0 8px 28px rgba(0, 0, 0, 0.1);
}

.hero .btn-watch-video {
    font-size: 16px;
    transition: 0.5s;
    margin-left: 25px;
    color: var(--default-color);
    font-weight: 600;
}

.hero .btn-watch-video i {
    color: var(--accent-color);
    font-size: 32px;
    transition: 0.3s;
    line-height: 0;
    margin-right: 8px;
}

.hero .btn-watch-video:hover {
    color: var(--accent-color);
}

.hero .btn-watch-video:hover i {
    color: color-mix(in srgb, var(--accent-color), transparent 15%);
}

.hero .animated {
    animation: up-down 2s ease-in-out infinite alternate-reverse both;
}

@media (max-width: 640px) {
    .hero h1 {
        font-size: 28px;
        line-height: 36px;
    }

    .hero p {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 30px;
    }

    .hero .btn-get-started,
    .hero .btn-watch-video {
        font-size: 13px;
    }
}

.hero .hero-waves {
    display: block;
    width: 100%;
    height: 60px;
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 3;
}

.hero .wave1 use {
    animation: move-forever1 10s linear infinite;
    animation-delay: -2s;
    fill: var(--default-color);
    opacity: 0.6;
}

.hero .wave2 use {
    animation: move-forever2 8s linear infinite;
    animation-delay: -2s;
    fill: var(--default-color);
    opacity: 0.4;
}

.hero .wave3 use {
    animation: move-forever3 6s linear infinite;
    animation-delay: -2s;
    fill: var(--default-color);
}

@keyframes move-forever1 {
    0% {
        transform: translate(85px, 0%);
    }

    100% {
        transform: translate(-90px, 0%);
    }
}

@keyframes move-forever2 {
    0% {
        transform: translate(-90px, 0%);
    }

    100% {
        transform: translate(85px, 0%);
    }
}

@keyframes move-forever3 {
    0% {
        transform: translate(-90px, 0%);
    }

    100% {
        transform: translate(85px, 0%);
    }
}

@keyframes up-down {
    0% {
        transform: translateY(10px);
    }

    100% {
        transform: translateY(-10px);
    }
}

/*--------------------------------------------------------------
  # About Section
  --------------------------------------------------------------*/
.about {
    padding-top: 100px;
}

.about .content h3 {
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    padding: 10px 20px;
    background: color-mix(in srgb, var(--accent-color), transparent 95%);
    color: var(--accent-color);
    border-radius: 7px;
    display: inline-block;
}

.about .content h2 {
    font-weight: 700;
}

.about .content p:last-child {
    margin-bottom: 0;
}

.about .content .read-more {
    background: var(--accent-color);
    color: var(--contrast-color);
    font-family: var(--heading-font);
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    padding: 12px 24px;
    border-radius: 5px;
    transition: 0.3s;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.about .content .read-more i {
    font-size: 18px;
    margin-left: 5px;
    line-height: 0;
    transition: 0.3s;
}

.about .content .read-more:hover {
    background: color-mix(in srgb, var(--accent-color), transparent 20%);
    padding-right: 19px;
}

.about .content .read-more:hover i {
    margin-left: 10px;
}

.about .icon-box {
    background-color: var(--surface-color);
    padding: 50px 40px;
    box-shadow: 0px 10px 50px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    transition: all 0.3s ease-out 0s;
}

.about .icon-box i {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
    font-size: 32px;
    line-height: 0;
    transition: all 0.4s ease-out 0s;
    background-color: color-mix(in srgb, var(--accent-color), transparent 95%);
    color: var(--accent-color);
}

.about .icon-box h3 {
    margin-bottom: 10px;
    font-size: 24px;
    font-weight: 700;
}

.about .icon-box p {
    margin-bottom: 0;
}

.about .icon-box:hover i {
    background-color: var(--accent-color);
    color: var(--contrast-color);
}

.about .icon-boxes .col-md-6:nth-child(2) .icon-box,
.about .icon-boxes .col-md-6:nth-child(4) .icon-box {
    margin-top: -40px;
}

@media (max-width: 768px) {

    .about .icon-boxes .col-md-6:nth-child(2) .icon-box,
    .about .icon-boxes .col-md-6:nth-child(4) .icon-box {
        margin-top: 0;
    }
}

/*--------------------------------------------------------------
  # Features Section
  --------------------------------------------------------------*/
.features .features-item {
    background-color: var(--surface-color);
    display: flex;
    align-items: center;
    padding: 20px;
    transition: 0.3s;
    border: 1px solid color-mix(in srgb, var(--default-color), transparent 90%);
    position: relative;
}

.features .features-item i {
    font-size: 32px;
    padding-right: 10px;
    line-height: 0;
}

.features .features-item h3 {
    font-weight: 700;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-size: 16px;
}

.features .features-item h3 a {
    color: var(--heading-color);
    transition: 0.3s;
}

.features .features-item:hover {
    border-color: var(--accent-color);
}

.features .features-item:hover h3 a {
    color: var(--accent-color);
}

/*--------------------------------------------------------------
  # Stats Section
  --------------------------------------------------------------*/
.stats i {
    background-color: var(--surface-color);
    color: var(--accent-color);
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
    width: 54px;
    height: 54px;
    font-size: 24px;
    border-radius: 50px;
    border: 2px solid var(--background-color);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
}

.stats .stats-item {
    background-color: var(--surface-color);
    margin-top: -27px;
    padding: 30px 30px 25px 30px;
    width: 100%;
    position: relative;
    text-align: center;
    box-shadow: 0px 2px 35px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    z-index: 0;
}

.stats .stats-item span {
    font-size: 36px;
    display: block;
    font-weight: 700;
    color: color-mix(in srgb, var(--default-color), transparent 20%);
}

.stats .stats-item p {
    padding: 0;
    margin: 0;
    font-family: var(--heading-font);
    font-size: 16px;
}

/*--------------------------------------------------------------
  # Details Section
  --------------------------------------------------------------*/
.details .features-item {
    color: color-mix(in srgb, var(--default-color), transparent 20%);
}

.details .features-item+.features-item {
    margin-top: 100px;
}

@media (max-width: 640px) {
    .details .features-item+.features-item {
        margin-top: 40px;
    }
}

.details .features-item h3 {
    font-weight: 700;
    font-size: 26px;
}

.details .features-item ul {
    list-style: none;
    padding: 0;
}

.details .features-item ul li {
    padding-bottom: 10px;
    display: flex;
    align-items: center;
}

.details .features-item ul li:last-child {
    padding-bottom: 0;
}

.details .features-item ul i {
    font-size: 20px;
    padding-right: 4px;
    color: var(--accent-color);
}

.details .features-item p:last-child {
    margin-bottom: 0;
}

/*--------------------------------------------------------------
  # Gallery Section
  --------------------------------------------------------------*/
.gallery .gallery-item {
    overflow: hidden;
    border-right: 3px solid var(--background-color);
    border-bottom: 3px solid var(--background-color);
}

.gallery .gallery-item img {
    transition: all ease-in-out 0.4s;
}

.gallery .gallery-item:hover img {
    transform: scale(1.1);
}

/*--------------------------------------------------------------
  # Testimonials Section
  --------------------------------------------------------------*/
.testimonials {
    padding: 80px 0;
    position: relative;
}

.testimonials:before {
    content: "";
    background: color-mix(in srgb, var(--background-color), transparent 30%);
    position: absolute;
    inset: 0;
    z-index: 2;
}

.testimonials .testimonials-bg {
    position: absolute;
    inset: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
}

.testimonials .container {
    position: relative;
    z-index: 3;
}

.testimonials .testimonials-carousel,
.testimonials .testimonials-slider {
    overflow: hidden;
}

.testimonials .testimonial-item {
    text-align: center;
}

.testimonials .testimonial-item .testimonial-img {
    width: 100px;
    border-radius: 50%;
    border: 6px solid color-mix(in srgb, var(--default-color), transparent 85%);
    margin: 0 auto;
}

.testimonials .testimonial-item h3 {
    font-size: 20px;
    font-weight: bold;
    margin: 10px 0 5px 0;
}

.testimonials .testimonial-item h4 {
    font-size: 14px;
    margin: 0 0 15px 0;
    color: color-mix(in srgb, var(--default-color), transparent 40%);
}

.testimonials .testimonial-item .stars {
    margin-bottom: 15px;
}

.testimonials .testimonial-item .stars i {
    color: #ffc107;
    margin: 0 1px;
}

.testimonials .testimonial-item .quote-icon-left,
.testimonials .testimonial-item .quote-icon-right {
    color: color-mix(in srgb, var(--default-color), transparent 40%);
    font-size: 26px;
    line-height: 0;
}

.testimonials .testimonial-item .quote-icon-left {
    display: inline-block;
    left: -5px;
    position: relative;
}

.testimonials .testimonial-item .quote-icon-right {
    display: inline-block;
    right: -5px;
    position: relative;
    top: 10px;
    transform: scale(-1, -1);
}

.testimonials .testimonial-item p {
    font-style: italic;
    margin: 0 auto 15px auto;
}

.testimonials .swiper-wrapper {
    height: auto;
}

.testimonials .swiper-pagination {
    margin-top: 20px;
    position: relative;
}

.testimonials .swiper-pagination .swiper-pagination-bullet {
    width: 12px;
    height: 12px;
    background-color: color-mix(in srgb, var(--default-color), transparent 50%);
    opacity: 0.5;
}

.testimonials .swiper-pagination .swiper-pagination-bullet-active {
    background-color: var(--default-color);
    opacity: 1;
}

@media (min-width: 992px) {
    .testimonials .testimonial-item p {
        width: 80%;
    }
}

/*--------------------------------------------------------------
  # Team Section
  --------------------------------------------------------------*/
.team .member {
    position: relative;
}

.team .member .pic {
    overflow: hidden;
    margin-bottom: 50px;
}

.team .member .member-info {
    background-color: var(--surface-color);
    color: color-mix(in srgb, var(--default-color), transparent 20%);
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    position: absolute;
    bottom: -50px;
    left: 20px;
    right: 20px;
    padding: 20px 15px;
    overflow: hidden;
    transition: 0.5s;
}

.team .member h4 {
    font-weight: 700;
    margin-bottom: 10px;
    font-size: 16px;
    position: relative;
    padding-bottom: 10px;
}

.team .member h4::after {
    content: "";
    position: absolute;
    display: block;
    width: 50px;
    height: 1px;
    background: color-mix(in srgb, var(--default-color), transparent 60%);
    bottom: 0;
    left: 0;
}

.team .member span {
    font-style: italic;
    display: block;
    font-size: 13px;
}

.team .member .social {
    position: absolute;
    right: 15px;
    bottom: 15px;
}

.team .member .social a {
    transition: color 0.3s;
    color: color-mix(in srgb, var(--default-color), transparent 70%);
}

.team .member .social a:hover {
    color: var(--accent-color);
}

.team .member .social i {
    font-size: 16px;
    margin: 0 2px;
}

/*--------------------------------------------------------------
  # Pricing Section
  --------------------------------------------------------------*/
.pricing .pricing-item {
    background-color: color-mix(in srgb, var(--accent-color), transparent 96%);
    padding: 40px 40px;
    height: 100%;
    border-radius: 15px;
}

.pricing h3 {
    font-weight: 600;
    margin-bottom: 15px;
    font-size: 20px;
}

.pricing h4 {
    color: var(--accent-color);
    font-size: 48px;
    font-weight: 700;
    font-family: var(--heading-font);
    margin-bottom: 0;
}

.pricing h4 sup {
    font-size: 28px;
}

.pricing h4 span {
    color: color-mix(in srgb, var(--default-color), transparent 50%);
    font-size: 18px;
    font-weight: 500;
}

.pricing .description {
    font-size: 14px;
}

.pricing .cta-btn {
    border: 1px solid var(--default-color);
    color: var(--default-color);
    display: block;
    text-align: center;
    padding: 10px 35px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 500;
    font-family: var(--heading-font);
    transition: 0.3s;
    margin-top: 20px;
    margin-bottom: 6px;
}

.pricing .cta-btn:hover {
    background: var(--accent-color);
    color: var(--contrast-color);
    border-color: var(--accent-color);
}

.pricing ul {
    padding: 0;
    list-style: none;
    color: color-mix(in srgb, var(--default-color), transparent 30%);
    text-align: left;
    line-height: 20px;
}

.pricing ul li {
    padding: 10px 0;
    display: flex;
    align-items: center;
}

.pricing ul li:last-child {
    padding-bottom: 0;
}

.pricing ul i {
    color: #059652;
    font-size: 24px;
    padding-right: 3px;
}

.pricing ul .na {
    color: color-mix(in srgb, var(--default-color), transparent 60%);
}

.pricing ul .na i {
    color: color-mix(in srgb, var(--default-color), transparent 60%);
}

.pricing ul .na span {
    text-decoration: line-through;
}

.pricing .featured {
    position: relative;
}

.pricing .featured .popular {
    position: absolute;
    top: 15px;
    right: 15px;
    background-color: var(--accent-color);
    color: var(--contrast-color);
    padding: 4px 15px 6px 15px;
    margin: 0;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 500;
}

.pricing .featured .cta-btn {
    background: var(--accent-color);
    color: var(--contrast-color);
    border-color: var(--accent-color);
}

@media (max-width: 992px) {
    .pricing .box {
        max-width: 60%;
        margin: 0 auto 30px auto;
    }
}

@media (max-width: 767px) {
    .pricing .box {
        max-width: 80%;
        margin: 0 auto 30px auto;
    }
}

@media (max-width: 420px) {
    .pricing .box {
        max-width: 100%;
        margin: 0 auto 30px auto;
    }
}

/*--------------------------------------------------------------
  # Faq Section
  --------------------------------------------------------------*/
.faq .content h3 {
    font-weight: 400;
    font-size: 34px;
}

.faq .content p {
    color: color-mix(in srgb, var(--default-color), transparent 30%);
}

.faq .faq-container {
    margin-top: 15px;
}

.faq .faq-container .faq-item {
    background-color: var(--surface-color);
    position: relative;
    padding: 20px;
    margin-bottom: 20px;
    box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.faq .faq-container .faq-item:last-child {
    margin-bottom: 0;
}

.faq .faq-container .faq-item h3 {
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;
    margin: 0 30px 0 32px;
    transition: 0.3s;
    cursor: pointer;
}

.faq .faq-container .faq-item h3 span {
    color: var(--accent-color);
    padding-right: 5px;
}

.faq .faq-container .faq-item h3:hover {
    color: var(--accent-color);
}

.faq .faq-container .faq-item .faq-content {
    display: grid;
    grid-template-rows: 0fr;
    transition: 0.3s ease-in-out;
    visibility: hidden;
    opacity: 0;
}

.faq .faq-container .faq-item .faq-content p {
    margin-bottom: 0;
    overflow: hidden;
}

.faq .faq-container .faq-item .faq-icon {
    position: absolute;
    top: 22px;
    left: 20px;
    font-size: 22px;
    line-height: 0;
    transition: 0.3s;
    color: var(--accent-color);
}

.faq .faq-container .faq-item .faq-toggle {
    position: absolute;
    top: 20px;
    right: 20px;
    font-size: 16px;
    line-height: 0;
    transition: 0.3s;
    cursor: pointer;
}

.faq .faq-container .faq-item .faq-toggle:hover {
    color: var(--accent-color);
}

.faq .faq-container .faq-active h3 {
    color: var(--accent-color);
}

.faq .faq-container .faq-active .faq-content {
    grid-template-rows: 1fr;
    visibility: visible;
    opacity: 1;
    padding-top: 10px;
}

.faq .faq-container .faq-active .faq-toggle {
    transform: rotate(90deg);
    color: var(--accent-color);
}

/*--------------------------------------------------------------
  # Contact Section
  --------------------------------------------------------------*/
.contact .info-item+.info-item {
    margin-top: 40px;
}

.contact .info-item i {
    color: var(--accent-color);
    background: color-mix(in srgb, var(--accent-color), transparent 92%);
    font-size: 20px;
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    transition: all 0.3s ease-in-out;
    margin-right: 15px;
}

.contact .info-item h3 {
    padding: 0;
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 5px;
}

.contact .info-item p {
    padding: 0;
    margin-bottom: 0;
    font-size: 14px;
}

.contact .info-item:hover i {
    background: var(--accent-color);
    color: var(--contrast-color);
}

.contact .php-email-form {
    height: 100%;
}

.contact .php-email-form input[type=text],
.contact .php-email-form input[type=email],
.contact .php-email-form textarea {
    font-size: 14px;
    padding: 10px 15px;
    box-shadow: none;
    border-radius: 0;
    color: var(--default-color);
    background-color: color-mix(in srgb, var(--background-color), transparent 50%);
    border-color: color-mix(in srgb, var(--default-color), transparent 80%);
}

.contact .php-email-form input[type=text]:focus,
.contact .php-email-form input[type=email]:focus,
.contact .php-email-form textarea:focus {
    border-color: var(--accent-color);
}

.contact .php-email-form input[type=text]::placeholder,
.contact .php-email-form input[type=email]::placeholder,
.contact .php-email-form textarea::placeholder {
    color: color-mix(in srgb, var(--default-color), transparent 70%);
}

.contact .php-email-form button[type=submit] {
    color: var(--contrast-color);
    background: var(--accent-color);
    border: 0;
    padding: 10px 30px;
    transition: 0.4s;
    border-radius: 50px;
}

.contact .php-email-form button[type=submit]:hover {
    background: color-mix(in srgb, var(--accent-color), transparent 25%);
}

/*--------------------------------------------------------------
  # Starter Section Section
  --------------------------------------------------------------*/
.starter-section {
    /* Add your styles here */
}