.slot-form {
  max-width: 850px;
  margin: 60px auto;
  padding: 40px;
  border: 3px solid #ddd;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.05);
  background: #fff;
}

.slot-form input {
  width: 200px;
  margin-bottom: 30px;
}

.slot-form ::placeholder {
  font-size: 13px;
}

.slot-form-h2-tag {
  margin-bottom: 60px;
  text-align: center;
  font-size: 30px;
  /* font-family: 'NexaDemo-Bold'; */
  color: #014D2F;
}

.slot-form-span {
  text-decoration: none;
  font-size: 20px;
  color: black;
  margin-left: -10px;
}

.slot-form-headers {
  text-decoration: none;
  font-size: 20px;
  color: black;
  margin-left: -10px;
  font-weight: 500;
}

.slot-form-p-tag {
  font-size: 18px;
  font-weight: lighter;
}

.slot-form-lable {
  margin: 10px;
  display: flex;
}

.phoneNumber {
  width: 300px;
}

.timePicker {
  width: 100px;
}

.check-icon {
  width: 60px;
  color: #014D2F;
  margin-top: -30px;
}