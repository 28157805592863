.UserProfileDiv {
    margin-top: 175px;
    padding: 0 100px;
}



@media screen and (max-width: 1086px) {
    .UserProfileDiv {
        padding: 30px;
    }

}