.update-profile-form {
    max-width: 850px;
    margin: 60px auto;
    padding: 40px;
    border: 3px solid #ddd;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.05);
    background: #fff;
}

.update-profile-div {
    margin-top: 30px;
}

.update-profile-input {
    margin-bottom: 20px;
    width: 300px;
}

.update-profile-h2-tag {
    margin-bottom: 20px;
    text-align: center;
    font-size: 30px;
    /* font-family: 'NexaDemo-Bold'; */
    color: #014D2F;
}

.update-profile-span {
    text-decoration: none;
    font-size: 20px;
    color: black;
}

.update-profile-lable {
    display: flex;
}