.give-access-div {
  margin-top: 90px;
  padding: 0 30px;
}

.give-access {
  max-width: 700px;
  margin: 60px auto;
  padding: 40px;
  border: 3px solid rgb(154, 154, 154);
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.05);
  background: #fff;
  text-align: center;
}

/* .give-access input {
  width: 300px;
  margin-bottom: 30px;
} */

.give-access-table {
  width: 650;
}

.give-access-h2-tag {
  margin-bottom: 20px;
  text-align: center;
  font-size: 30px;
  color: #014D2F;
}


.give_Access_icons {
  transform: scale(1.2);
  margin-right: 13px;
  color: #014D2F;
}

.give_Access-lable {
  display: flex;
}