.PackageList {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 20px;
}

.PackageList a {
  border-bottom: 8px solid #F3C722;
  background-color: #fff;
  padding: 16px;
  border-radius: 6px;
  box-shadow: 10px 8px 8px rgba(0, 0, 0, 0.05);
  text-decoration: none;
  color: inherit;
}

.PackageList .atag {
  background-color: #fff;
  padding: 16px;
  border-radius: 6px;
  box-shadow: 10px 8px 8px rgba(0, 0, 0, 0.05);
  text-decoration: none;
  color: inherit;
}

.PackageList h3 {
  /* font-family: 'NexaDemo-Bold'; */
  font-size: 20px;
}

.PackageList h2 {
  /* font-family: 'NexaDemo-Bold'; */
  font-size: 0.9em;
}

.PackageList p {
  /* font-family: 'NexaDemo-Bold'; */
  font-size: 0.9em;
  margin-top: 18px;
}

.PackageList .assigned-to {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 2px solid #eee;
}

.PackageList ul {
  margin: 10px 0;
  display: flex;
}

.PackageList li {
  margin-right: 10px;
}

.PackageList .avatar {
  width: 30px;
  height: 30px;
}

.swal-button--confirm {
  background: #014D2F;
}

.swal-button--cancel {
  background: #aaa;
}

.swal-button--danger {
  background: #a00;
}

.swal-button--confirm:hover {
  background-color: #4CAF50;
  color: white;
}

.slot-list-icons {
  transform: scale(1.2);
  margin-right: 5px;
  margin-top: 8px;
  color: #F3C722;
}