.branch-auth-form {
  max-width: 700px;
  margin: 60px auto;
  padding: 40px;
  border: 3px solid #dddf;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.05);
  background: #fff;
}

/* .branch-auth-form input {
  width: 300px;
  margin-bottom: 30px;
} */

.branch-auth-form-span {
  outline: none;
  margin: 0%;
  text-decoration: none;
  font-size: 20px;
  color: black;
  margin-left: -10px;
  font-weight: 500;
}

.branch-auth-form-input {
  width: 350px;
  margin: 0;
  margin-left: -10px;
  transition: border-color 0.3s;
}

.branch-auth-form-input :focus {
  outline: none;
}

.form-control-textBox {
  resize: none;
  margin-top: 10px;
  margin-bottom: 10px;
  outline: none;
  width: 350px;
  margin-left: -10px;
}


.branch-auth-form-p-tag {
  font-size: 18px;
  margin-bottom: 10px;
}

.branch-auth-form-h2-tag {
  margin-bottom: 20px;
  text-align: center;
  font-size: 30px;
  /* font-family: 'NexaDemo-Bold'; */
  color: #014D2F;
}

.branch-auth-form-textarea-tag {
  resize: none;
}

.branch-auth-form-lable {
  margin: 0px;
  display: flex;
}