.wrapper {
  width: fit-content;
  max-width: auto;
  height: fit-content;
  margin-top: 10px;
  margin-left: 10px;
  text-align: left;
}

/* .title {
  color: grey;
  font-size: 18px;
} */

/* span {
  text-decoration: none;
  font-size: 20px;
  color: black;
  font-family: 'NexaDemo-Bold';
}

h3 {
  font-size: 23px;
  margin-right: 25px;
}

button {
  border: none;
  outline: 0;
  display: inline-block;
  padding: 8px;
  color: white;
  background-color: #000;
  text-align: center;
  cursor: pointer;
  width: 90%;
  font-size: 18px;
}

button:hover,
a:hover {
  opacity: 0.7;
} */

.svg_icons {
  transform: scale(1.2);
  margin-right: 13px;
  color: seagreen;
}