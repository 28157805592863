.staff-auth-form {
  padding: 20px;
  max-width: 550px;
  max-height: 100%;
  margin: 100px auto;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 1px 0px rgba(79, 79, 79, 0.8);
  border-radius: 25px;
}

.staff-auth-form-title {
  text-align: center;
  margin: 0;
  font-size: 2rem;
}

.staff-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
  max-width: 100%;
}


.tab-group2 {
  border: 2px;
  list-style: none;
  padding: 0;
  margin: 0 0 40px 0;

}

.hrLine2 {
  width: 100%;
  margin-left: 0%;
  align-items: center;
  color: "#7d7d7d";
}

.tab-group2 :after {
  content: "",
}

.tab-group2 li a {
  color: #014D2F;
  display: block;
  text-decoration: none;
  padding: 15px;
  background: rgba(gray-light, .25);
  color: gray-light;
  font-size: 15px;
  float: left;
  width: 50%;
  text-align: center;
  cursor: pointer;
  transition: .5s ease;
}

.tab-group2 :hover {
  background: rgb(248, 248, 248);
  color: #014D2F;
}

.staff-auth-form button {
  background-color: #014D2F;
  width: 250px;
  border: none;
  color: white;
  padding: 15px 80px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  font-size: 13px;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.staff-row button:hover {
  opacity: 0.8;
}

.staff-row input {
  background-color: #f6f6f6;
  border: none;
  color: #0d0d0d;
  padding: 15px 32px;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 15px;
  width: 85%;
  border: 2px solid #f6f6f6;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 11px 11px 11px 11px;
}

.link-staffsignup {
  font-size: 15px;
  padding: 5px;
  margin-top: 15px;
}

.link-staffsignup a {
  font-weight: bold;
}