.Vendor-List {
    margin-top: 40px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    grid-gap: 20px;
}

.Vendor-List-div {
    margin-top: 150px;
    padding: 0 100px
}

.vendor-list-cards {
    border-bottom: 8px solid #F3C722;
    background-color: #fff;
    padding: 16px;
    border-radius: 6px;
    box-shadow: 10px 8px 8px rgba(0, 0, 0, 0.05);
    text-decoration: none;
    color: inherit;
}

/* .Vendor-List-a-tag {
    border-bottom: 8px solid #F3C722;
    background-color: #fff;
    padding: 16px;
    border-radius: 6px;
    box-shadow: 10px 8px 8px rgba(0, 0, 0, 0.05);
    text-decoration: none;
    color: inherit;
} */

.Vendor-List .atag {
    background-color: #fff;
    padding: 16px;
    border-radius: 6px;
    border-left-color: coral;
    box-shadow: 10px 8px 8px rgba(0, 0, 0, 0.05);
    text-decoration: none;
    color: inherit;
}

.Vendor-List h3 {
    font-size: 20px;
}

.Vendor-List h2 {
    font-size: 0.9em;
}

.Vendor-List p {
    font-size: 0.9em;
    margin-top: 18px;
}

.Vendor-List .assigned-to {
    margin-top: 20px;
    padding-top: 10px;
    border-top: 2px solid #eee;
}

.Vendor-List ul {
    margin: 10px 0;
    display: flex;
}

.Vendor-List li {
    margin-right: 10px;
}

.Vendor-List .avatar {
    width: 30px;
    height: 30px;
}

.swal-button--confirm {
    background: #014D2F;
}

.swal-button--cancel {
    background: #aaa;
}

.swal-button--danger {
    background: #a00;
}

.swal-button--confirm:hover {
    background-color: #4CAF50;
    color: white;
}

.slot-list-icons {
    transform: scale(1.2);
    margin-right: 5px;
    margin-top: 8px;
    color: #F3C722;
}

@media screen and (max-width: 1086px) {
    .Vendor-List-div {
        padding: 0px;
    }

}