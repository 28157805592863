.main-aside {
  padding-left: 0px;
  padding-top: 25px;
}

.ul-list {
  padding-left: 0px;
}

.link-of-item {
  text-decoration: none;
}

.navbar-heading {
  font-size: 18px;
}

.logout-style {
  color: red;
  padding-left: 15px;
  font-size: 18px;
  text-decoration: none;
}

.menu-list .a-heading {
  padding-left: 0px;
  text-decoration: none;
  display: block;
  border-radius: 0px 6px 6px 0px;
  padding: 0.5em 0.75em;
  color: var(--primary-color);
}

.footer {
  margin-top: -12px;
}

@media screen and (max-width: 768px) {
  #menu-toggle:checked+.nav-menu {
    display: block;
  }
}