.signup-auth-form {
  padding: 20px;
  max-width: 550px;
  max-height: 100%;
  margin: 100px auto;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 1px 0px rgba(79, 79, 79, 0.8);
  border-radius: 25px;
}

/* .label {
  text-align: left;
} */

.signup-auth-form-title {
  text-align: center;
  margin: 0;
  font-size: 2rem;
}

/* .signup-auth-form span {
  padding: 5px 32px;
} */


.tab-group {
  border: 2px;
  list-style: none;
  padding: 0;
  margin: 0 0 40px 0;

}

.hrLine {
  width: 100%;
  margin-left: 0%;
  align-items: center;
  color: "#7d7d7d";
}

.tab-group :after {
  content: "",
}

.tab-group li a {
  color: #014D2F;
  display: block;
  text-decoration: none;
  padding: 15px;
  background: rgba(gray-light, .25);
  color: gray-light;
  font-size: 15px;
  float: left;
  width: 50%;
  text-align: center;
  cursor: pointer;
  transition: .5s ease;
}

.tab-group :hover {
  background: rgb(248, 248, 248);
  color: #014D2F;
}

.signup-auth-form button {
  background-color: #014D2F;
  width: 250px;
  border: none;
  color: white;
  padding: 15px 80px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  font-size: 13px;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.vendor-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
  max-width: 100%;
}

.vendor-row input {
  background-color: #f6f6f6;
  border: none;
  color: #0d0d0d;
  padding: 15px 32px;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 15px;
  border: 2px solid #f6f6f6;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 11px 11px 11px 11px;
}

.vendor-row-date input {
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  border: none;
  font-size: 1.3rem;
  padding-bottom: 1rem;
  box-shadow: inset -2px -2px 2px 2px rgba(14, 13, 13, 0.1);
  transition: box-shadow 0.2s ease-in;
}

.link-signup {
  font-size: 15px;
  padding: 5px;
  margin-top: 15px;
}

.link-signup a {
  font-weight: bold;
}

.row button:hover {
  opacity: 0.8;
}

.vendor-row span {
  margin-left: -20px;
  margin-top: 10px;
  align-self: flex-start;
  color: rgb(0, 0, 0);
}

.phoneInput {
  margin-left: 25px;
  width: 100%;
}

.tab-group li.is-active a {
  background-color: #e4ece4;
  border-color: #e4ece4;
  color: #014D2F;
  z-index: 1;
}