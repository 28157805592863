.Admin-Vendor-List {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 20px;
}

.statistics-container {
  margin-top: 175px;
  padding: 0 100px;
}

.reservations-header {
  margin-top: 20px;
  padding: 0 100px;
}

.card-title {
  font-size: 25px;
  font-weight: bold;
}

.reservation-column {
  padding: 10px;
}

/* .reservation-card {
  background-color: black;
} */





@media (max-width: 1120px) {
  .reservation-column {
    /* display: flex; */
    flex-wrap: wrap;
    flex-direction: row;
    padding: 10px;
    width: 270px;
  }

  .card {
    width: 100%;
  }

}

@media only screen and (max-width: 772px) {
  .rows {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
  }

  .card {
    width: 100%;
    height: auto;
    /* Resetting the height to auto */
  }

  .reservation-card-content {
    padding: 0%;
  }
}

@media only screen and (max-width: 498px) {
  .reservation-card-content {
    padding: 0;
    /* background-color: aqua; */
    width: max-content;
  }

  .reservation-content {
    white-space: nowrap;
    /* Prevent text from wrapping */
    overflow: hidden;
    /* Hide overflow */
    text-overflow: ellipsis;
  }

  .reservations-header {
    margin-top: 20px;
    padding: 0 50px;
  }

  .card-title {
    padding-left: 50px;
  }
}