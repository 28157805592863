.Privacy-Policy-div {

    text-align: center;
    padding-top: 95px;
}

.min-div {
    margin: 50px;
    background-color: #ffffffe2;
    padding: 15px;
    border-radius: 20px;
}

.Privacy-Policy-title {
    font-size: 40px;
    color: #014D2F;
}



.min-headers {
    color: #014D2F;
    font-size: 25px;
    text-align: center;
    padding: 20px;
    font-weight: bold;
}

.sub-title {
    font-weight: bold;
    color: #014D2F;
}