.auth-form {
  max-width: 700px;
  margin: 60px auto;
  padding: 40px;
  border: 3px solid #ddd;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.05);
  background: #fff;
}

.auth-form input {
  width: 300px;
  margin-bottom: 30px;
}

.auth-form-h2-tag {
  /* font-family: 'NexaDemo-Bold'; */
  font-size: 30px;
  color: " #014D2F";
}

/* span {
  margin-top: "30px"
} */