.terms-conditions-div {

    text-align: center;
    padding-top: 95px;
}

.min-header-style {
    font-size: 40px;
    color: #014D2F;
}

.definitions-div {
    margin: 50px;
    background-color: #ffffffe2;
    padding: 15px;
    border-radius: 20px;
}

.headers {
    color: #014D2F;
    font-size: 25px;
    text-align: center;
    padding: 20px;
    font-weight: bold;
}