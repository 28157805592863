.VendorReservationList {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 20px;
}

.VendorReservationList div {
  border-bottom: 8px solid #F3C722;
  background-color: #fff;
  padding: 16px;
  border-radius: 6px;
  box-shadow: 10px 8px 8px rgba(0, 0, 0, 0.05);
  text-decoration: none;
  color: inherit;
}

.VendorReservationList .atag {
  background-color: #fff;
  padding: 16px;
  border-radius: 6px;
  box-shadow: 10px 8px 8px rgba(0, 0, 0, 0.05);
  text-decoration: none;
  color: inherit;
}

.VendorReservationList h3 {
  font-size: 20px;
}

.VendorReservationList h2 {
  font-size: 0.9em;
}

.VendorReservationList p {
  font-size: 0.9em;
  margin-top: 18px;
}

.VendorReservationList .assigned-to {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 2px solid #eee;
}

.VendorReservationList ul {
  margin: 10px 0;
  display: flex;
}

.VendorReservationList li {
  margin-right: 10px;
}

.VendorReservationList .avatar {
  width: 30px;
  height: 30px;
}

.swal-button--confirm {
  background: #014D2F;
}

.swal-button--cancel {
  background: #aaa;
}

.swal-button--danger {
  background: #a00;
}

.swal-button--confirm:hover {
  background-color: #4CAF50;
  color: white;
}

.slot-list-icons {
  transform: scale(1.2);
  margin-right: 5px;
  margin-top: 8px;
  color: #F3C722;
}