.map-auth-form {
    padding: 20px;
    max-width: 550px;
    max-height: 850px;
    margin: 100px auto;
    background-color: #FFFFFF;
    border-radius: 5px;
}

.map-input-tag {
    padding: 8px 6px;
    font-size: 1em;
    color: #777;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.map-lable-tag {
    display: block;
    margin: 24px auto;
}