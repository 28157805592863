.login-auth-form {
  padding: 20px;
  max-width: 550px;
  max-height: 100%;
  margin: 100px auto;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 1px 0px rgba(79, 79, 79, 0.8);
  border-radius: 25px;
}

.tabs.is-toggle li.is-active a {
  background-color: #e4ece4;
  border-color: #e4ece4;
  color: #e4ece4;
  z-index: 1;
}


.tab-content {
  border: 1px solid #dee2e6;
  border-top: transparent;
  padding: 15px;
}

.tab-content .tab-pane {
  background-color: #FFF;
  color: #e4ece4;
  min-height: 200px;
  height: auto;
}

.login-auth-form-div {
  height: 100vh;
  position: "absulte";
  margin: 0px;
}

.login-auth-form-title {
  font-weight: bold;
  text-align: center;
  padding: 15px;
  margin: 0;
  font-size: 2rem;
}

.login-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
}

.rowInput {
  background-color: #f6f6f6;
  border: none;
  color: #0d0d0d;
  padding: 15px 32px;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 15px;
  border: 2px solid #f6f6f6;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 11px 11px 11px 11px;
}



.loginButton {
  background-color: #014D2F;
  border: none;
  width: 250px;
  color: white;
  padding: 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  text-transform: uppercase;
  font-size: 13px;
  -webkit-border-radius: 5px 5px 5px 5px;
  border-radius: 5px 5px 5px 5px;
  margin: 5px 0px 40px 0px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.link {
  font-size: 15px;
  padding: 5px;
  margin-top: 15px;
}



.loginLink {
  font-weight: bold;
}

.login_icons {
  transform: scale(1.2);
  margin-right: 13px;
  color: seagreen;
}

@media screen and (max-width: 436px) {
  .link {
    font-size: 12px;
    font-weight: bold;
  }
}