.order-list {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 10px;
  padding: 0 30px;

}

.order-list-total {
  width: 100%;
  border-bottom: 8px solid #F3C722;
  background-color: #fff;
  padding: 16px;
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  grid-gap: 20px;
}

.Admin-Vendor-List .card {
  border-bottom: 8px solid #F3C722;
  background-color: #fff;
  padding: 16px;
  border-radius: 6px;
  box-shadow: 10px 8px 8px rgba(0, 0, 0, 0.05);
  text-decoration: none;
  color: inherit;
}

.order-list .card {
  border-bottom: 8px solid #F3C722;
  background-color: #fff;
  padding: 16px;
  border-radius: 6px;
  box-shadow: 10px 8px 8px rgba(0, 0, 0, 0.05);
  text-decoration: none;
  color: inherit;
}

.order-list .atag {
  background-color: #fff;
  padding: 16px;
  border-radius: 6px;
  box-shadow: 10px 8px 8px rgba(0, 0, 0, 0.05);
  text-decoration: none;
  color: inherit;
}

.order-list h3 {
  font-size: 23px;
  margin-bottom: 10px;
}

.order-list h2 {
  font-size: 0.9em;
}

.order-list p {
  font-size: 20px;
  margin-bottom: 10px;
  font-weight: bold;
}

.order-list .assigned-to {
  margin-top: 20px;
  padding-top: 10px;
  border-top: 2px solid #eee;
}

.order-list ul {
  margin: 10px 0;
  display: flex;
}

.order-list li {
  margin-right: 10px;
}

.order-list .avatar {
  width: 30px;
  height: 30px;
}

.swal-button--confirm {
  background: #014D2F;
}

.swal-button--cancel {
  background: #aaa;
}

.swal-button--danger {
  background: #a00;
}

.swal-button--confirm:hover {
  background-color: #4CAF50;
  /* Green */
  color: white;
}