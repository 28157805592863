.App {
  /* background color for whole pages */
  background-color:none;

}


.App .AppContainer {
  flex-grow: 1;
}


@media screen and (max-width: 1094px) {
  .App .AppContainer {
    margin: 50px;
  }
}