@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

/* variables */
:root {
  --heading-color: #444;
  --text-color: #999;
  --primary-color: #0C594F;
  --highlight-color: #d13267;
  /* --bg-color: #f4f4f4; */
  --bg-color: #e3e3e3;
  --reject-color: #ff0000;
}

/* base styles */
html {
  font-family: Poppins, serif;
  margin: 0;
  font-size: 1.1em;
  background-color: rgb(255, 255, 255);
}







@media screen and (max-width :895px) {
  html {
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

@media screen and (max-width: 1086px) {
  .mainDiv {
    padding: 50px;
  }

}

li {
  list-style-type: none;
}

.slot-green {
  color: #014D2F;
}

.mainDiv {
  margin-top: 100px;
  padding: 0 50px;
}


.slotBackground {
  background-color: "#e6eee6",
}

@media screen and (max-width :486px) {
  html {
    /* background: none; */
    /* background: url(../assets/images/background-remove.png) no-repeat center center fixed; */
    /* background: var(--bg-color); */
    /* height: 100vh; */
    /* background-attachment: fixed; */
    background-position: right;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

/* 
ul,
li,
p,
h1,
h2,
h3,
h4 {
  margin: 0;
  padding: 0;
} */

ul {
  list-style-type: none;
}

/* layout */
.page-title {
  font-size: 1em;
  color: var(--heading-color);
  display: inline-block;
}

.slotButton {
  background: #fff;
  padding: 8px 12px;
  border-radius: 4px;
  color: var(--primary-color);
  cursor: pointer;
  font-size: 1em;
  border: 1px solid var(--primary-color);
  margin-left: 0px;
}

.slotButton:hover {
  color: #fff;
  background-color: var(--primary-color);
}


.btnCheck {
  background: #fff;
  padding: 8px 12px;
  border-radius: 4px;
  color: var(--primary-color);
  cursor: pointer;
  font-size: 1em;
  border: 1px solid var(--primary-color);
}

.btnCheck:hover {
  color: #fff;
  background-color: var(--primary-color);
}

.btnRej {
  background: #fff;
  padding: 8px 12px;
  border-radius: 4px;
  color: var(--reject-color);
  cursor: pointer;
  font-size: 1em;
  border: 1px solid var(--reject-color);
}

.btnRej:hover {
  color: #fff;
  background-color: var(--reject-color);
}

/* forms */
label {
  display: block;
  margin: 24px auto;
}

label span {
  display: block;
  margin-bottom: 6px;
}

input,
textarea {
  padding: 8px 6px;
  font-size: 1em;
  color: #777;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ddd;
  border-radius: 4px;
}

textarea {
  min-height: 160px;
}

.error {
  color: red;
  background: pink;
  border: 1px solid red;
  border-radius: 4px;
  padding: 8px;
  margin: 10px 0;
}